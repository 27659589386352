import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rem } from "polished"
import { navigate } from "gatsby"

import SEO from "../../components/seo"

import PosterImage from "../../assets/images/posters/believe.png"

const Title = styled.h1`
  font-size: ${rem(24)};
  margin-top: ${rem(72)};
  margin-bottom: ${rem(24)};
  text-align: center;
`

class SharePage extends React.Component {
  componentDidMount = () => {
    window.setTimeout(() => {
      navigate(`/`)
    }, 500)
  }

  render() {
    return (
      <React.Fragment>
        <Title>
          Survey is loading...
        </Title>

        <SEO social_image={PosterImage} />
      </React.Fragment>
    )
  }
}

export default SharePage

SharePage.propTypes = {
  data: PropTypes.object,
}
